import { Injectable } from '@angular/core';
import { Store } from './store';
import { LanguageState } from './language.state';

@Injectable()
export class LanguageStore extends Store<LanguageState> {
  constructor() {
    super(new LanguageState());
  }

  public setAuthenticated(value: boolean) {
    this.setState({
      ...this.state,
      Authenticated: value,
    });
  }

  public setRTL(value: boolean) {
    this.setState({
      ...this.state,
      RTL: value,
    });
  }
  protected setState(nextState: LanguageState): void {
    super.setState(nextState);
  }

  public setLanguage(lid: string) {
    this.setState({
      ...this.state,
      LID: lid,
    });
  }
}
