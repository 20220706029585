import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthService } from './_modules/authentication/auth.service';
import { TranslationLoader } from './_helpers/TranslationLoader';
import * as Sentry from '@sentry/angular';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { TitleStrategy, Router } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { TranslateModule, TranslateLoader, MissingTranslationHandler, TranslateService } from '@ngx-translate/core';
import { MessageService, RTL } from '@progress/kendo-angular-l10n';
import { ConfigService } from './_modules/shared/_services/config/config.service';
import { LocaleService } from './_modules/shared/_services/locale.service';
import { DashboardStore } from './_modules/shared/_stores/dashboard.store';
import { LanguageStore } from './_modules/shared/_stores/language.store';
import { UserStore } from './_modules/shared/_stores/user.store';
import { CookieService } from 'ngx-cookie-service';
import { MyMissingTranslationHandler } from '@/_modules/shared/MyMissingTranslationHandler';
import { TitleStrategyService } from '@/_modules/shared/strategies/title/title-strategy.service';
import { NoCacheHeadersInterceptor } from '@/_helpers/NoCacheHeadersInterceptor';
import { ErrorIntercept } from '@/_helpers/httperror.interceptor';
import { ICON_SETTINGS } from '@progress/kendo-angular-icons';
import { registerLocaleData } from '@angular/common';

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, AuthService],
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
    }),
  ],
  providers: [
    TranslateService,
    LanguageStore,
    UserStore,
    LocaleService,
    DashboardStore,
    CookieService,
    MessageService,
    { provide: TitleStrategy, useClass: TitleStrategyService },
    { provide: RTL, useFactory: enableRTL },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercept,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, ConfigService, LanguageStore],
      multi: true,
    },
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
    [{ provide: ICON_SETTINGS, useValue: { type: 'font' } }],
  ],
})
export class AppModule {}

export function tokenGetter() {
  return getCookie('access_token');
}

export function HttpLoaderFactory(httpClient: HttpClient, auth: AuthService) {
  return new TranslationLoader(httpClient, auth, '/api/Language/Translations/');
}

export function getCookie(cname: any) {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

export function enableRTL(): boolean {
  const dir = getCookie('dir');
  if (dir === 'rtl') {
    return true;
  }
  return false;
}

export function appInitializerFactory(
  translate: TranslateService,
  configService: ConfigService,
  languageStore: LanguageStore,
) {
  return async () => {
    // Get language from localStorage or browser language, fallback to 'en'
    const language = localStorage.getItem('LID') || navigator.language.split('-')[0] || 'en';

    // Set up initial language state
    translate.setDefaultLang(language);
    await translate.use(language).toPromise();

    // Initialize the language store state
    languageStore.setLanguage(language);

    // Store the selected language in localStorage
    localStorage.setItem('LID', language);

    await Promise.resolve();
  };
}
