import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AuthService } from '@/_modules/authentication/auth.service';

export class TranslationLoader implements TranslateLoader {
  constructor(private http: HttpClient, private auth: AuthService, private authenticatedUrl: string) {}

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<Object> {
    let url: string = this.authenticatedUrl;
    const authenticated: boolean = false;
    if (this.auth.isAuthenticated()) {
      url = '/api/Language/Translations/authenticated/';
    }

    if (lang === '') {
      lang = 'en';
    }
    console.log(`loading language ${lang}`);

    return this.http.get(`${url}${lang}?nocache=${new Date().getTime()}`);
  }
}
