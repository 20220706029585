

import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SimpleResult } from '@/_modules/shared/_models/_generated/SimpleResult';
import { RegisterResponse } from '@/_modules/shared/_models/_generated/RegisterResponse';
import { UserLoginResponse } from '@/_modules/shared/_models/_generated/UserLoginResponse';
import { TanValidationResponse } from '@/_modules/shared/_models/_generated/TanValidationResponse';
import { BaseField } from '@/_modules/shared/_models/_generated/BaseField';
import { UserInfo } from '@/_modules/shared/_models/_generated/UserInfo';
import { OnlineReport } from '@/_modules/shared/_models/_generated/OnlineReport';
import { TopCompetence } from '@/_modules/shared/_models/_generated/TopCompetence';
import { DownloadableReport } from '@/_modules/shared/_models/_generated/DownloadableReport';
import { RegistrationDropdownItem } from '@/_modules/shared/_models/_generated/RegistrationDropdownItem';
import { TanRequest } from '@/_modules/shared/_models/_generated/TanRequest';
import { ForgotPasswordRequest } from '@/_modules/shared/_models/_generated/ForgotPasswordRequest';
import { ConfirmResetPasswordRequest } from '@/_modules/shared/_models/_generated/ConfirmResetPasswordRequest';
import { UserRegisterRequest } from '@/_modules/shared/_models/_generated/UserRegisterRequest';
import { UserValidationRequest } from '@/_modules/shared/_models/_generated/UserValidationRequest';
import { UserRegistrationRequest } from '@/_modules/shared/_models/_generated/UserRegistrationRequest';
import { SendTransferCodeRequest } from '@/_modules/shared/_models/_generated/SendTransferCodeRequest';
import { UserLoginRequest } from '@/_modules/shared/_models/_generated/UserLoginRequest';
import { PersonaliaField } from '@/_modules/shared/_models/_generated/PersonaliaField';

@Injectable({ providedIn: 'root' })
export class UserService {
    private http = inject(HttpClient);


   
    
    
    
    public resetPassword = (request: ForgotPasswordRequest) : Observable<SimpleResult<boolean>> => {
                  let url = `api/User/login/forgotpassword`;
         return this.http.request<SimpleResult<boolean>>("post", url , {body: request});
    }
    public confirmResetPassword = (request: ConfirmResetPasswordRequest) : Observable<SimpleResult<boolean>> => {
                  let url = `api/User/login/confirm/resetpassword`;
         return this.http.request<SimpleResult<boolean>>("post", url , {body: request});
    }
    public register = (registerRequest: UserRegisterRequest) : Observable<RegisterResponse> => {
                  let url = `api/User/register`;
         return this.http.request<RegisterResponse>("post", url , {body: registerRequest});
    }
    public validate = (validationRequest: UserValidationRequest) : Observable<RegisterResponse> => {
                  let url = `api/User/validate`;
         return this.http.request<RegisterResponse>("post", url , {body: validationRequest});
    }
    public finishRegistration = (registrationRequest: UserRegistrationRequest) : Observable<RegisterResponse> => {
                  let url = `api/User/registration`;
         return this.http.request<RegisterResponse>("post", url , {body: registrationRequest});
    }
    public sendTransferCode = (transferRequest: SendTransferCodeRequest) : Observable<RegisterResponse> => {
                  let url = `api/User/sendtransfercode`;
         return this.http.request<RegisterResponse>("post", url , {body: transferRequest});
    }
    public loginUser = (login: UserLoginRequest) : Observable<UserLoginResponse> => {
                  let url = `api/User/login`;
         return this.http.request<UserLoginResponse>("post", url , {body: login});
    }
    public validateTan = (login: TanRequest) : Observable<TanValidationResponse> => {
                  let url = `api/User/validateTan`;
         return this.http.request<TanValidationResponse>("post", url , {body: login});
    }
    public savePersonalia = (fields: PersonaliaField[]) : Observable<SimpleResult<boolean>> => {
                  let url = `api/User/personaliafields`;
         return this.http.request<SimpleResult<boolean>>("post", url , {body: fields});
    }
    public updateCandidatePersonalia = (userInfo: UserInfo) : Observable<SimpleResult<boolean>> => {
                  let url = `api/User/candidate`;
         return this.http.request<SimpleResult<boolean>>("post", url , {body: userInfo});
    }
    
    public getMoreInfoOnRegistering = (lang: string) : Observable<SimpleResult<string>> => {
                 let url = `api/User/register/moreinfourl/${encodeURIComponent(lang)}`;
         return this.http.request<SimpleResult<string>>("get", url );
    }
    public isValidResetGuid = (resetguid: string) : Observable<SimpleResult<boolean>> => {
                 let url = `api/User/login/confirm/isvalidresetguid/${encodeURIComponent(resetguid)}`;
         return this.http.request<SimpleResult<boolean>>("post", url );
    }
    public isAlreadyValidated = (userguid: string) : Observable<SimpleResult<boolean>> => {
                 let url = `api/User/login/confirm/isalreadyvalidated/${encodeURIComponent(userguid)}`;
         return this.http.request<SimpleResult<boolean>>("post", url );
    }
    public getCompanyText = (label: string, lang: string) : Observable<SimpleResult<string>> => {
                 let url = `api/User/GetCompanyText/${encodeURIComponent(label)}/${encodeURIComponent(lang)}`;
         return this.http.request<SimpleResult<string>>("get", url );
    }
    public gotoDashboard = (tannr: number) : Observable<boolean> => {
                 let url = `api/User/gotodashboard/${tannr}`;
         return this.http.request<boolean>("get", url );
    }
    public getLanguageForScreen = (customerId: string) : Observable<boolean> => {
                 let url = `api/User/login?customerId=${encodeURIComponent(customerId)}`;
         return this.http.request<boolean>("get", url );
    }
    public sendNoPrivacyEmail = () : Observable<boolean> => {
                 let url = `api/User/noprivacy`;
         return this.http.request<boolean>("post", url );
    }
    public getPersonaliaFields = () : Observable<BaseField[]> => {
                 let url = `api/User/personaliafields`;
         return this.http.request<BaseField[]>("get", url );
    }
    public getCandidatePersonalia = () : Observable<UserInfo> => {
                 let url = `api/User/candidate`;
         return this.http.request<UserInfo>("get", url );
    }
    public useSimpleQuestions = () : Observable<boolean> => {
                 let url = `api/User/UseSimpleQuestions`;
         return this.http.request<boolean>("get", url );
    }
    public getReport = (report: string, language: string) : Observable<boolean> => {
                 let url = `api/User//api/reports/external/${encodeURIComponent(report)}/${encodeURIComponent(language)}`;
         return this.http.request<boolean>("get", url );
    }
    public getReports = (language: string) : Observable<OnlineReport[]> => {
                 let url = `api/User/external/${encodeURIComponent(language)}`;
         return this.http.request<OnlineReport[]>("get", url );
    }
    public getNivo = (language: string) : Observable<{ Key: string, Value: string}[]> => {
                 let url = `api/User/nivo/${encodeURIComponent(language)}`;
         return this.http.request<{ Key: string, Value: string}[]>("get", url );
    }
    public getGender = (language: string) : Observable<{ Key: string, Value: string}[]> => {
                 let url = `api/User/gender/${encodeURIComponent(language)}`;
         return this.http.request<{ Key: string, Value: string}[]>("get", url );
    }
    public getCompetencies = (language: string) : Observable<TopCompetence[]> => {
                 let url = `api/User/competencies/${encodeURIComponent(language)}`;
         return this.http.request<TopCompetence[]>("get", url );
    }
    public getDownloadableReportsList = (language: string) : Observable<DownloadableReport[]> => {
                 let url = `api/User/downloadablereportlist/${encodeURIComponent(language)}`;
         return this.http.request<DownloadableReport[]>("get", url );
    }
    public getRegistrationDropdown = (validationCode: string) : Observable<RegistrationDropdownItem[]> => {
                 let url = `api/User/GetRegistrationDropdown/${encodeURIComponent(validationCode)}`;
         return this.http.request<RegistrationDropdownItem[]>("get", url );
    }
    public isSelfRegister = () : Observable<boolean> => {
                 let url = `api/User/isselfregister`;
         return this.http.request<boolean>("get", url );
    }
    public isFastLink = (candcode: string) : Observable<boolean> => {
                 let url = `api/User/isfastlink/${encodeURIComponent(candcode)}`;
         return this.http.request<boolean>("get", url );
    }
    public getTanRequestInfo = (fastlink: string) : Observable<TanRequest> => {
                 let url = `api/User/gettanrequestinfo/${encodeURIComponent(fastlink)}`;
         return this.http.request<TanRequest>("get", url );
    }
    public fastLogin = (guid: string) : Observable<boolean> => {
                 let url = `api/User//fastlogin/${encodeURIComponent(guid)}`;
         return this.http.request<boolean>("get", url );
    }
    public jobPortLogin = (guid: string) : Observable<boolean> => {
                 let url = `api/User//jobportlogin/${encodeURIComponent(guid)}`;
         return this.http.request<boolean>("get", url );
    }
}

 
