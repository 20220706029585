import { Routes } from '@angular/router';
import { CanActivateDashboard } from '@/_helpers/auth.guard.dashboard';

export const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('@/_modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [CanActivateDashboard],
  },
  {
    path: 'login',
    loadChildren: () => import('@/_modules/authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  {
    path: 'fastlogin/:code',
    redirectTo: '/login/validate/:code',
  },
  {
    path: 'jobportlogin/:code',
    redirectTo: '/dashboard/:code',
  },
  { path: '**', redirectTo: 'login' },
];
