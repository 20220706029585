import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  public handle(params: MissingTranslationHandlerParams) {
    let trans = params.translateService;
    let key = params.key.toLowerCase();
    let curTranslations = trans.translations[trans.currentLang];

    if (curTranslations == null || curTranslations == undefined) {
      return params.key.toUpperCase();
    }

    let keys = Object.keys(curTranslations);
    for (let i = 0; i < keys.length; i++) {
      if (keys[i].toLowerCase() == key) {
        let value = trans.instant(keys[i]);
        trans.set(params.key, value);
        return value;
      }
    }

    console.log('missing translation : "' + params.key + '"');

    if (params.translateService.currentLang === params.translateService.defaultLang) {
      return params.key.toUpperCase();
    }
  }
}
