import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { KeyValuePair } from '@/_modules/shared/_models/_generated/KeyValuePair';
import { DateFormats } from '@/_modules/shared/_models/_generated/DateFormats';
import { SelectableLanguage } from '@/_modules/shared/_models/_generated/SelectableLanguage';
import { SimpleResult } from '@/_modules/shared/_models/_generated/SimpleResult';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private http = inject(HttpClient);


  public getCountries = (lang: string): Observable<KeyValuePair<string, string>[]> => {
    let url = `api/Language/Countries/${encodeURIComponent(lang)}`;
    return this.http.request<KeyValuePair<string, string>[]>('get', url);
  };
  public getDateFormat = (lang: string): Observable<DateFormats> => {
    let url = `api/Language/DateFormats/${encodeURIComponent(lang)}`;
    return this.http.request<DateFormats>('get', url);
  };
  public authenticatedTranslations = (lang: string): Observable<{ [key: string]: string }> => {
    let url = `api/Language/Translations/authenticated/${encodeURIComponent(lang)}`;
    return this.http.request<{ [key: string]: string }>('get', url);
  };
  public translations = (lang: string): Observable<{ [key: string]: string }> => {
    let url = `api/Language/Translations/${encodeURIComponent(lang)}`;
    return this.http.request<{ [key: string]: string }>('get', url);
  };
  public getLanguages = (defaultLanguage: string): Observable<SelectableLanguage[]> => {
    let url = `api/Language/languages/${encodeURIComponent(defaultLanguage)}`;
    return this.http.request<SelectableLanguage[]>('get', url);
  };
  public getText = (lang: string, tag: string): Observable<SimpleResult<string>> => {
    let url = `api/Language/text/${encodeURIComponent(lang)}/${encodeURIComponent(tag)}`;
    return this.http.request<SimpleResult<string>>('get', url);
  };
}
