import { Injectable } from '@angular/core';
import { Store } from './store';
import { DashboardState } from './dashboard.state';
import { Exercise } from '../_models/_generated/Exercise';

@Injectable()
export class DashboardStore extends Store<DashboardState> {
  constructor() {
    super(new DashboardState());
  }

  protected setState(nextState: DashboardState): void {
    super.setState(nextState);
  }

  public setExercises(current: Exercise, allExercises: Exercise[]) {
    let newState: DashboardState = {
      AllExercises: allExercises,
      StateId: current === null ? 1 : current.TypeID === 2 ? 3 : 2,
      CurrentExercise: current,
    };

    this.setNewState(newState);
  }

  public setNewState(nextState: DashboardState): void {
    this.setState(nextState);
  }

  public setStateId(id: number) {
    this.setState({
      ...this.state,
      StateId: id,
    });
  }

  public setExercise(exercise: Exercise) {
    this.setState({
      ...this.state,
      StateId: exercise === null ? 1 : exercise.TypeID === 2 ? 3 : 2,
      CurrentExercise: exercise,
    });
  }

  public NextExercise() {
    for (let i = 0; i < this.state.AllExercises.length; i++) {
      let item = this.state.AllExercises[i];
      if (item.AnswerCount < item.QuestionCount && item.CorrectLanguage) {
        this.setExercise(item);
        return;
      }
    }

    this.setStateId(1);
  }
}
