import { Injectable } from '@angular/core';
import { Store } from './store';
import { UserState } from './user.state';

@Injectable()
export class UserStore extends Store<UserState> {
  constructor() {
    super(new UserState());
  }

  protected setState(nextState: UserState): void {
    super.setState(nextState);
  }

  public setCompanyName(name: string) {
    this.setState({ ...this.state, companyName: name });
  }

  public setCustomer(sub: string, useCompany: string) {
    this.setState({
      ...this.state,
      customerId: sub,
      useCustomerNameAsCompany: useCompany == '1',
    });
  }

  public setTanRow(tanRow: number) {
    this.setState({
      ...this.state,
      tanrow: tanRow,
    });
  }

  public setSimple(defaultSimple: boolean) {
    this.setState({
      ...this.state,
      selectSimpleByDefault: defaultSimple,
      isSimpleSetAlready: true,
    });
  }

  public setDashboard(dashboard: boolean) {
    this.setState({
      ...this.state,
      dashboard: dashboard,
    });
  }

  public setPrivacy(agreed: boolean) {
    this.setState({
      ...this.state,
      agreedPrivacy: agreed,
    });
  }

  public setAuthenticated(isAuthenticated: boolean) {
    this.setState({
      ...this.state,
      isAuthenticated: isAuthenticated,
    });
  }
}
