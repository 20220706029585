import { Injectable } from '@angular/core';
import { delay, firstValueFrom, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  configuration = new Configuration();

  mareTest: string;
  constructor() {}

  load(): Promise<any> {
    this.configuration.mareTest = '#42f5b0';

    return firstValueFrom(of(this.configuration));
  }

  getConfig(): Configuration {
    return this.configuration;
  }
}

export class Configuration {
  mareTest: string;
}
