<ul>
  <li>
    <a [routerLink]="['/dashboard/exercises']" routerLinkActive="active" [attr.aria-label]="'MyExercises' | translate">
      <span><i class="fa-duotone fa-messages-question"></i></span>
    </a>
    <span>{{ 'MyExercises' | translate }}</span>
  </li>
  @if (shouldShowResultsPage) {
    <li>
      <a [routerLink]="['/dashboard/results']" routerLinkActive="active" [attr.aria-label]="'MyResults' | translate">
        <span class="fa-duotone fa-square-poll-horizontal"></span>
      </a>
      <span>{{ 'MyResults' | translate }}</span>
    </li>
  }
  @if (!isJobPortCandidate) {
    <li>
      <a [routerLink]="['/dashboard/profile']" routerLinkActive="active" [attr.aria-label]="'MyProfile' | translate">
        <span><i class="fa-duotone fa-file-user"></i></span>
      </a>
      <span>{{ 'MyProfile' | translate }}</span>
    </li>
  }
</ul>
