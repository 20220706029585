import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, inject } from '@angular/core';
import { LanguageService } from '@/_modules/shared/_services/_generated/LanguageService';
import { untilComponentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { SelectableLanguage } from '@/_modules/shared/_models/_generated/SelectableLanguage';
import { TranslateService } from '@ngx-translate/core';
import { LanguageStore } from '../_stores/language.store';
import { MessageService } from '@progress/kendo-angular-l10n';
import { LocaleService } from '@/_modules/shared/_services/locale.service';
import { DropDownListComponent, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-language-selector',
  imports: [CommonModule, DropDownsModule],
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  standalone: true,
})
export class LanguageSelectorComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  private languageService = inject(LanguageService);
  private translate = inject(TranslateService);
  private localeService = inject(LocaleService);
  private languageStore = inject(LanguageStore);
  private messageService = inject(MessageService);

  @Input() menuOpen: boolean;
  @Output() menuOpenChange: EventEmitter<boolean> = new EventEmitter();

  public Languages: SelectableLanguage[] = [];
  public isRTL: boolean = false;
  public loading: boolean = true;

  public set SelectedLanguage(value: SelectableLanguage) {
    if (!value) return;

    this.translate.use(value.LID);
    this.languageStore.setLanguage(value.LID);
    this.languageStore.setRTL(value.IsRTL);
    this.messageService.notify(value.IsRTL);
    this.localeService.set(value.Culture);
    this.isRTL = value.IsRTL;
    localStorage.setItem('LID', value.LID);
  }

  public get SelectedLanguage() {
    const currentLang = this.translate.currentLang;
    if (!currentLang || !this.Languages.length) return null;

    return this.Languages.find((l) => l.LID.toLowerCase() === currentLang.toLowerCase()) || null;
  }

  public valueChange(value: any): void {
    this.menuOpen = false;

    this.menuOpenChange.emit(this.menuOpen);
  }

  public ngOnDestroy() {}

  public getUrl(language: SelectableLanguage) {
    if (language === null || language === undefined) {
      return null;
    }

    let flagName = language.Culture.substring(3, 5) + '.png';

    return 'https://images.analyzer.global/flags/' + flagName;
  }

  ngOnInit() {
    // Get the current language from the store or fallback sources
    const currentLang =
      this.languageStore.state?.LID || this.translate.currentLang || localStorage.getItem('LID') || 'en';

    this.languageService
      .getLanguages(currentLang)
      .pipe(untilComponentDestroyed(this))
      .subscribe({
        next: (lang) => {
          this.Languages = lang;

          const currentLanguage = lang.find((l) => l.LID.toLowerCase() === currentLang.toLowerCase());

          if (currentLanguage) {
            // Only update RTL and culture settings, don't change the language
            this.languageStore.setRTL(currentLanguage.IsRTL);
            this.messageService.notify(currentLanguage.IsRTL);
            this.localeService.set(currentLanguage.Culture);
            this.isRTL = currentLanguage.IsRTL;
          } else {
            // If current language not found in available languages, use default
            const defaultLang = lang.find((l) => l.IsDefault) || lang[0];
            if (defaultLang) {
              this.SelectedLanguage = defaultLang;
            }
          }
          this.loading = false;
        },
        error: (error) => {
          console.error('Error loading languages:', error);
          this.loading = false;
        },
      });
  }
}
