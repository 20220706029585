import { Injectable, LOCALE_ID, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IntlService, CldrIntlService, setData } from '@progress/kendo-angular-intl';

@Injectable()
export class LocaleService {
  private http = inject(HttpClient);
  intl = inject(IntlService);
  localeId = inject(LOCALE_ID);

  private url = 'https://unpkg.com/@progress/kendo-angular-intl/locales/json/{0}/all.json';
  private loaded: any = {};
  private static _number = 0;
  public num: number = 0;
  constructor() {
    const localeId = this.localeId;

    LocaleService._number++;
    this.num = LocaleService._number;
    this.set(localeId);
  }

  public set(localeId: string): void {
    if (this.loaded[localeId]) {
      this.setLocale(localeId);

      return;
    }

    let l = localeId;
    l = l.substring(0, 2);

    this.http.get(this.intl.format(this.url, l)).subscribe((result) => {
      // set the recieved locale data
      setData(result);

      this.loaded[localeId] = true;

      // set the local as current
      this.setLocale(localeId);
    });
  }

  private setLocale(localeId: string): void {
    (this.intl as CldrIntlService).localeId = localeId;

    this.localeId = localeId;
  }
}
