<!-- <link *ngIf="cssUrl() !== ''" rel="stylesheet" [href]="sanitizer.bypassSecurityTrustResourceUrl(cssUrl())" /> -->
<div class="page customer_alignment" [dir]="textDirection" [lang]="language">
  <div role="banner" class="page-header">
    <a href="#" aria-label="Logo image" class="logo">
      <img
        class="auth-logo"
        width="200px"
        id="logo"
        src="https://images.analyzer.global/LDQM/customers/{{ this.hostName }}/logo.svg"
        onerror="this.src = 'https://images.analyzer.global/LDQM/customers/localhost/logo.svg'"
        aria-label="Logo image"
        alt="Logo image"
      />
    </a>

    <div class="screen">
      <div [style.float]="textDirection == 'rtl' ? 'left' : 'right'" style="margin-top: 7px">
        <app-language-selector></app-language-selector>
        @if (this.IsAuthenticated) {
          <button
            kendoButton
            [translate]="'Log out'"
            [style.margin-left]="textDirection != 'rtl' ? '10px' : '0px'"
            [style.margin-right]="textDirection == 'rtl' ? '10px' : '0px'"
            themeColor="primary"
            iconClass="fa-solid fa-person-to-door"
            (click)="this.Logout()"
          ></button>
        }
      </div>
    </div>
    <div class="mobile-nav">
      <button kendoButton aria-expanded="false" aria-controls="mobileMenu" class="menu-button" (click)="clickMenu()">
        <span class="menu-header">
          @if (!menuOpen) {
            <i class="transition-transform duration-300 ease-in-out transform fas fa-bars hover:rotate-180"></i>
          } @else {
            <i class="transition-transform duration-300 ease-in-out transform rotate-180 fas fa-times"></i>
          }
          {{ 'Menu' | translate }}</span
        >
      </button>
    </div>
  </div>

  <div class="page-content">
    @if (ShowNavigation) {
      <div role="navigation" class="side-nav screen">
        <app-left-navigation
          [shouldShowResultsPage]="shouldShowResultsPage"
          [isJobPortCandidate]="isJobPortCandidate"
        ></app-left-navigation>
      </div>
    }
    @if (menuOpen) {
      <div class="transition-all duration-300 ease-in-out mobile-menu" role="menu" #mobileMenu>
        @if (ShowNavigation) {
          <ul role="group">
            <li role="presentation">
              <a
                (click)="nevigateTo('/dashboard/exercises')"
                [routerLink]="['/dashboard/exercises']"
                role="menuitem"
                routerLinkActive="active"
              >
                <span><i class="fa-duotone fa-messages-question"></i></span>
              </a>
              <span>{{ 'MyExercises' | translate }}</span>
            </li>
            @if (shouldShowResultsPage) {
              <li role="presentation">
                <a
                  (click)="nevigateTo('/dashboard/results')"
                  [routerLink]="['/dashboard/results']"
                  routerLinkActive="active"
                  role="menuitem"
                >
                  <span><i class="fa-duotone fa-square-poll-horizontal"></i></span>
                </a>
                <span>{{ 'MyResults' | translate }}</span>
              </li>
            }
            @if (!isJobPortCandidate) {
              <li role="presentation">
                <a
                  (click)="nevigateTo('/dashboard/profile')"
                  [routerLink]="['/dashboard/profile']"
                  routerLinkActive="active"
                  role="menuitem"
                >
                  <span><i class="fa-duotone fa-file-user"></i></span>
                </a>
                <span>{{ 'MyProfile' | translate }}</span>
              </li>
            }
          </ul>
        }
        <div>
          <app-language-selector role="menuitem" [(menuOpen)]="menuOpen"></app-language-selector>
          @if (this.IsAuthenticated) {
            <button
              role="menuitem"
              [style.margin-left]="textDirection != 'rtl' ? '10px' : '0px'"
              [style.margin-right]="textDirection == 'rtl' ? '10px' : '0px'"
              [translate]="'Log out'"
              (click)="this.Logout()"
            ></button>
          }
        </div>
      </div>
    }
    <div class="contentRegion">
      <router-outlet></router-outlet>
    </div>
  </div>

  @if (this.showDisclaimer) {
    <div role="contentinfo" class="page-footer">
      <p [translate]="'[DISCLAIMERTAG]'" [translateParams]="{ currentyear: currentYear }"></p>
    </div>
  }
</div>
