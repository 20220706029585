import { Component, OnInit, OnDestroy, Input, inject } from '@angular/core';
import { Router, NavigationEnd, RouterModule } from '@angular/router';
import { LanguageStore } from '@/_modules/shared/_stores/language.store';
import { untilComponentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserService } from '@/_modules/shared/_services/_generated/UserService';

@Component({
  selector: 'app-left-navigation',
  templateUrl: './left-navigation.component.html',
  styleUrls: ['./left-navigation.component.scss'],
  standalone: true,
  imports: [TranslateModule, RouterModule],
})
export class LeftNavigationComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  private router = inject(Router);
  private languageStore = inject(LanguageStore);
  private translate = inject(TranslateService);
  private userController = inject(UserService);

  @Input() shouldShowResultsPage: boolean;
  @Input() isJobPortCandidate: boolean = false;
  public ngOnDestroy(): void {}

  public Options: NavigationOption[] = [];
  ngOnInit() {
    this.translate.onLangChange.pipe(untilComponentDestroyed(this)).subscribe((c) => {
      this.SetNavigation();
    });

    this.userController
      .getCandidatePersonalia()
      .pipe()
      .subscribe((c) => {
        this.shouldShowResultsPage = c.ShowMyResults;
      });

    this.router.events.pipe(untilComponentDestroyed(this)).subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.SetNavigation();
      }
    });

    this.SetNavigation();
  }

  private SetNavigation() {
    let options: NavigationOption[] = [
      { DisplayText: this.translate.instant('nav.Introduction'), Done: false, Current: false, Url: '/' },
      { DisplayText: this.translate.instant('nav.Privacy'), Done: false, Current: false, Url: '/privacy' },
      { DisplayText: this.translate.instant('nav.Personalia'), Done: false, Current: false, Url: '/personalia' },
      {
        DisplayText: this.translate.instant('nav.Excercise dashboard'),
        Done: false,
        Current: false,
        Url: '/dashboard',
      },
    ];

    let route = this.router.routerState.snapshot.url;
    if (route === '/intro') {
      route = '/';
    }

    let current: number = -1;
    for (let i = 0; i < options.length; i++) {
      if (options[i].Url === route) {
        current = i;
        options[i].Current = true;
        break;
      } else {
        options[i].Current = false;
      }
    }

    for (let i = 0; i < current; i++) {
      options[i].Done = true;
    }

    this.Options = options;
  }
}

export class NavigationOption {
  public DisplayText: string;
  public Done: boolean;
  public Current: boolean;
  public Url: string;
}
