<kendo-dropdownlist
  [data]="Languages"
  [textField]="'LID'"
  [valueField]="'Culture'"
  [(value)]="SelectedLanguage"
  (valueChange)="valueChange($event)"
  [popupSettings]="{ width: 200 }"
  [loading]="loading"
  aria-label="Select Language"
>
  <ng-template kendoDropDownListValueTemplate kendoDropDownListItemTemplate let-dataItem>
    @if (dataItem) {
      <span class="{{ isRTL ? 'rtlFlag' : 'ltrFlag' }}">
        <img [src]="getUrl(dataItem)" alt="Flag of {{ dataItem?.Name }}" />
      </span>
      <span id="text">{{ dataItem?.Name | titlecase }}</span>
    } @else {
      <span>Loading...</span>
    }
  </ng-template>
</kendo-dropdownlist>
