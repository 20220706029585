import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '@/_modules/authentication/auth.service';
import { UserStore } from '../_modules/shared/_stores/user.store';

@Injectable({
  providedIn: 'root',
})
export class CanActivateDashboard  {
  private auth = inject(AuthService);
  private userStore = inject(UserStore);
  private router = inject(Router);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | import('@angular/router').UrlTree
    | Observable<boolean | import('@angular/router').UrlTree>
    | Promise<boolean | import('@angular/router').UrlTree> {
    /*let canOpen = false*/
    return new Observable<boolean>((obs) => {
      this.auth.canOpenDashboard().subscribe((isAuth) => {
        if (isAuth) {
          this.userStore.setAuthenticated(true);
          this.userStore.setDashboard(isAuth);
          obs.next(true);
        } else {
          this.router.navigate(['/login'], { replaceUrl: true });

          obs.next(false);
        }
      });
    });

    /*   return canOpen;*/
  }
}
