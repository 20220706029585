export class UserState {
  tanrow: number = -1;
  isAuthenticated: boolean = false;
  agreedPrivacy: boolean = false;
  dashboard: boolean = false;
  companyName: string = '';
  selectSimpleByDefault: boolean = false;
  isSimpleSetAlready: boolean = false;
  useCustomerNameAsCompany: boolean = false;
  customerId: string = '';
}
