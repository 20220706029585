import { Component, HostListener, OnInit, inject } from '@angular/core';
import { AuthService } from './_modules/authentication/auth.service';
import { UserStore } from './_modules/shared/_stores/user.store';
import { ConfigService } from './_modules/shared/_services/config/config.service';
import { DashboardStore } from './_modules/shared/_stores/dashboard.store';
import { LanguageStore } from '@/_modules/shared/_stores/language.store';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { style } from '@angular/animations';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UserService } from './_modules/shared/_services/_generated/UserService';
import { getCookie } from './app.module';
import { LanguageSelectorComponent } from './_modules/shared/language-selector/language-selector.component';
import { ButtonComponent } from '@progress/kendo-angular-buttons';
import { TranslateModule } from '@ngx-translate/core';
import { LeftNavigationComponent } from './_modules/shared/left-navigation/left-navigation.component';
import { RouterLinkActive, RouterLink, RouterOutlet } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    LanguageSelectorComponent,
    ButtonComponent,
    TranslateModule,
    LeftNavigationComponent,
    RouterLinkActive,
    RouterLink,
    RouterOutlet,
  ],
})
export class AppComponent implements OnInit {
  private document = inject<Document>(DOCUMENT);
  private auth = inject(AuthService);
  private userStore = inject(UserStore);
  private userController = inject(UserService);
  sanitizer = inject(DomSanitizer);
  private languageStore = inject(LanguageStore);
  private dashboardStore = inject(DashboardStore);
  private configService = inject(ConfigService);

  menuOpen = false;
  public ShowNavigation: boolean = this.auth.isAuthenticated();
  public IsAuthenticated: boolean = this.auth.isAuthenticated();
  public language: string = 'en';
  public currentYear: string = new Date().getFullYear().toString();
  public hostName: string;
  public shouldShowResultsPage: boolean = true;
  public isJobPortCandidate: boolean = false;
  public showDisclaimer: boolean = true;

  constructor() {
    const document = this.document;

    this.hostName = document.location.hostname;
    this.showDisclaimer = this.hostName.indexOf('ams.') < 0 ? true : false;
  }

  ngOnInit() {
    this.isJobPortCandidate = getCookie('isJobPortCandidate') ? true : false;

    this.userStore.state$.subscribe((c) => {
      this.ShowNavigation = c.isAuthenticated;
      this.IsAuthenticated = c.isAuthenticated;
    });

    //this.userController
    //  .getCandidatePersonalia()
    //  .pipe()
    //  .subscribe((c) => {
    //    this.shouldShowResultsPage = c.SelfRegistered || c.ShowMyResults;
    //  });

    this.languageStore.state$.subscribe((c) => {
      this.textDirection = c.RTL ? 'rtl' : 'ltr';
      if (c.LID != null) {
        this.language = c.LID.toLowerCase();
        this.document.documentElement.lang = this.language;
      }
    });

    // this.configService.load().then(() => {
    const brandColor = this.configService.getConfig().mareTest;

    const styleSheet = document.createElement('link');

    // document.documentElement.style.setProperty('--kendo-color-primary', '#d3d3d3');
    styleSheet.rel = 'style';
    styleSheet.href = style + '.css';

    document.getElementsByTagName('head')[0].appendChild(styleSheet);
    // });
  }

  // public cssUrl(): string {
  //   let hostName = location.hostname;
  //   if (this.IsAuthenticated) {
  //     return 'https://images.analyzer.global/LDQM/customers/' + hostName + '/style.authenticated.css';
  //   } else {
  //     return 'https://images.analyzer.global/LDQM/customers/' + hostName + '/style.css';
  //   }
  // }

  public textDirection = 'ltr';
  title = 'LDQ';

  deferredPrompt: any;
  showButton = false;

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
  }

  public nevigateTo(route: string) {
    this.clickMenu();
    // this.router.navigate([route]);
  }

  public Logout() {
    this.dashboardStore.setStateId(1);
    this.delete_cookie('access_token');
    this.delete_cookie('isJobPortCandidate');
    document.location.href = '/';
  }

  delete_cookie(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }

  clickMenu() {
    this.menuOpen = !this.menuOpen;
  }
}
