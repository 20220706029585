import { Injectable, inject } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from '@/_modules/shared/_services/_generated/UserService';
import { IntlService } from '@progress/kendo-angular-intl';
import { LocaleService } from '@/_modules/shared/_services/locale.service';
import { UserStore } from '../shared/_stores/user.store';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private jwtHelper = inject(JwtHelperService);
  private localeService = inject(LocaleService);
  private userService = inject(UserService);
  private userStore = inject(UserStore);

  InitializeUser(): Promise<boolean> {
    let promise = new Promise<boolean>((resolve, reject) => {
      this.userService.getLanguageForScreen('').subscribe(
        (data) => {
          let lang = 'nld';
          switch (lang) {
            case 'nld':
            case 'nl':
              this.localeService.set('nl');
              break;
            case 'd':
            case 'de':
              this.localeService.set('de');
              break;
            default:
              this.localeService.set('en');
              break;
          }

          resolve(true);
        },
        (error) => {
          resolve(false);
        },
      );
    });

    return promise;
  }

  public canOpenDashboard(): Observable<boolean> {
    if (!this.isAuthenticated()) {
      return of(false);
    }
    let tokens = this.jwtHelper.tokenGetter();
    let token: IDashboard = this.jwtHelper.decodeToken(tokens.toString());
    let tanrowid = Number(token.uid);
    return this.userService.gotoDashboard(tanrowid);
  }

  public isAuthenticated(): boolean {
    let tokens = this.jwtHelper.tokenGetter();
    if (tokens == null || tokens == undefined || tokens == '') {
      return false;
    }

    if (this.jwtHelper.isTokenExpired()) {
      return false;
    }
    let token: ICompany = this.jwtHelper.decodeToken(tokens.toString());
    let cust: ICUST = this.jwtHelper.decodeToken(tokens.toString());

    let companyName = token.comp;

    if (companyName.toLowerCase() === 'inbox') {
      companyName = token.parentComp;
    }
    this.userStore.setCompanyName(companyName);

    this.userStore.setCustomer(cust.sub, cust.useCustAsComp);

    if (!this.userStore.state.isSimpleSetAlready && !this.userStore.state.selectSimpleByDefault) {
      let simp: IDefaultSimple = this.jwtHelper.decodeToken(tokens.toString());
      this.userStore.setSimple(simp.defSimp === '1');
    }

    this.userService.useSimpleQuestions().subscribe((value) => {
      if (value) {
        this.userStore.setSimple(true);
      }
    });

    return true;
  }
}

interface IDashboard {
  uid(uid: number);
  dashboard: string;
}

interface ICompany {
  comp: string;
  parentComp: string;
}

interface ICUST {
  sub: string;
  useCustAsComp: string;
}

interface IDefaultSimple {
  defSimp: string;
}
