import { Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TitleStrategyService extends TitleStrategy {
  private readonly title = inject(Title);
  private translate = inject(TranslateService);


  updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);

    if (title !== undefined) {
      this.translate
        .stream(title)
        .pipe()
        .subscribe((res) => {
          this.title.setTitle(`${res} - Analyzer`);
        });
    } else {
      this.title.setTitle(`Analyzer`);
    }
  }
}
